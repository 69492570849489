import React, { useState } from "react";
import SignUp from "../components/SignUp";
import SignIn from "../components/SignIn";
import logo from "../assets/logo.png";
import Footer from "../components/Footer";


const AuthPage = () => {
  const [activeComponent, setActiveComponent] = useState("SignIn");

  return (
    <div className="auth-container">
      {/* Top Buttons */}
      <div className="auth-upperContainer">
        <div className="auth-buttonContainer">
          <button
            className={activeComponent === "SignIn" ? "auth-activeButton" : "auth-button"}
            onClick={() => setActiveComponent("SignIn")}
          >
            <h2 className={activeComponent === "SignIn" ? "auth-buttonTextActive" : "auth-buttonText"}>
              Sign In
            </h2>
          </button>
          <button
            className={activeComponent === "SignUp" ? "auth-activeButton" : "auth-button"}
            onClick={() => setActiveComponent("SignUp")}
          >
            <h2 className={activeComponent === "SignUp" ? "auth-buttonTextActive" : "auth-buttonText"}>
              Sign Up
            </h2>
          </button>
        </div>
        <div className="auth-imageLogoContainer">
          <img
            src={logo}
            alt="Good-People"
            className="auth-imageLogo"
          />
          <div className="auth-titleContainer">
            <h1 className="auth-title">WELCOME</h1>
            <h2 className="auth-title1">A SOCIAL FRIENDSHIP APP</h2>
          </div>
        </div>
      </div>

      {/* Render Component Based on State */}
      <div className="auth-lowerContainer">
        {activeComponent === "SignIn" && <SignIn />}
        {activeComponent === "SignUp" && <SignUp />}
      </div>
    </div>
  );
};

export default AuthPage;
