import React from 'react';

function TermsAndConditionsPage() {
  return (
    <div className="terms-container">
      <div className="privacy-header">
        <h1>Terms and Conditions</h1>
      </div>

      <div className="terms-content">
        <p>Welcome to Good People. By using our platform, you agree to abide by the following terms and conditions designed to foster a safe, respectful, and supportive community. Please read these terms carefully.</p>

        <h2>1. Community Guidelines</h2>
        <ul>
          <li>Users must treat others with respect and dignity. Harassment, bullying, and abusive behavior of any kind are strictly prohibited.</li>
          <li>Child abuse, exploitation, or any inappropriate conduct involving minors will result in immediate account termination and legal action.</li>
          <li>Sharing false, harmful, or misleading statements is not allowed.</li>
        </ul>

        <h2>2. Prohibited Content</h2>
        <ul>
          <li>Content promoting violence, hate speech, discrimination, or illegal activities is forbidden.</li>
          <li>Stupidity or inappropriate behavior leading to the harm of individuals or the community will not be tolerated.</li>
          <li>Users must refrain from using offensive language, threats, or any content that could harm others emotionally or mentally.</li>
        </ul>

        <h2>3. Account Responsibility</h2>
        <ul>
          <li>Users are responsible for all activity conducted through their accounts.</li>
          <li>Sharing login credentials or using another user’s account without permission is not allowed.</li>
        </ul>

        <h2>4. Privacy and Data Use</h2>
        <p>Good People is committed to protecting your personal data. Refer to our Privacy Policy for details on how we collect, use, and safeguard your information.</p>

        <h2>5. Violation and Enforcement</h2>
        <ul>
          <li>Good People reserves the right to suspend or terminate accounts that violate these terms.</li>
          <li>Serious violations, such as child abuse or illegal activities, will be reported to appropriate authorities.</li>
        </ul>

        <h2>6. User Obligations</h2>
        <ul>
          <li>Users must report any suspicious or harmful behavior immediately.</li>
          <li>All interactions and communications should comply with local laws and Good People’s policies.</li>
        </ul>

        <h2>7. Updates to Terms</h2>
        <p>Good People reserves the right to modify these terms at any time. Updates will be communicated through the app or website. Continued use of the platform signifies agreement to updated terms.</p>

        <p>By using Good People, you acknowledge and agree to these Terms & Conditions. Violations may result in suspension or permanent removal from the platform.</p>

        <p>If you have any questions or need further clarification, please contact our support team.</p>
      </div>
    </div>
  );
}

export default TermsAndConditionsPage;
