import React, { useEffect, useState } from "react";
import defaultProfilePic from "../assets/profile2.png"; // Static image for null profile pictures
import callButtonImage from "../assets/green_call.png"; // Call button image
import { CallFriendApi, GetFriendListApi } from "../services/Api";
import { ProgressBar } from "react-loader-spinner";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";

const FriendsPage = () => {
  const navigate = useNavigate();
  const [friendsList, setFriendsList] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchFriends = async () => {
    const response = await GetFriendListApi();
    if (response) {
      console.log("GetFriendListApi response", response);
      setFriendsList(response);
    }
    setLoading(false);
  };

  const handleStartCall = async (receiverId) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const senderId = userData.userId;
    setLoading(true);
    const response = await CallFriendApi({ senderId, receiverId });
    if (response) {
      const token1 = response.token;
      const channelName1 = response.channelName;
      const channelId1 = response.channelId;
      navigate("/friendsTalking", {
        state: {
          token1: token1,
          channelName1: channelName1,
          channelId1: channelId1,
        },
      });
      console.log("CallFriendApi response:", response);
      setLoading(false);
    } else {
      setLoading(false);
      alert("Unable to start the call");
      return;
    }
  };

  useEffect(() => {
    // Simulated API Response

    fetchFriends();
  }, []);

  const handleCall = (friendId) => {
    console.log(`Calling friend with ID: ${friendId}`);
    // Add logic for making the call
  };

  if (loading) {
    return (
      <div className="progress-bar">
        <ProgressBar
          visible={true}
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="progress-bar-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }

  return (
    <div className="friends-page-container">
      <Header />
      <h1 className="friends-page-title">My Friends</h1>
      <div className="friends-list">
        {friendsList.map((friend) => (
          <div key={friend.friendid} className="friend-item">
            <img
              src={friend.friendProfilePic || defaultProfilePic}
              alt={friend.friendName}
              className="friend-profile-pic"
            />
            <div className="friend-name">{friend.friendName}</div>
            <button
              className="friend-call-button"
              onClick={null
                // () => handleStartCall(friend.friendid)
              }
            >
              <img
                src={callButtonImage}
                alt="Call"
                className="friend-call-icon"
              />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FriendsPage;
