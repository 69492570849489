import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import EmotionList from "../components/EmotionList";
import PromoBanner from "../components/PromoBanner";
import FaqTile from "../components/FaqTile";
import startSpeaking from "../assets/start-speaking.png";
import questionsLogo from "../assets/qa.png";
import salary from "../assets/salary.png";
import how from "../assets/how.png";
import hourglass from "../assets/hour-glass.png";
import premium from "../assets/premium.png";
// Import the CSS file
import "../styles/Home.css";
import { UserTalktimeApi } from "../services/Api";

const HomePage = () => {
  const navigate = useNavigate();
  const faqData = [
    {
      logo: salary,
      question: "What is a Mini-hour?",
      description:
        "At Good People, a Mini Hour is 30 minutes of focused talk time, perfect for meaningful conversations or quick problem-solving. Designed to foster connection and clarity, it’s more than enough for someone seeking quick help, ensuring impactful engagement without overstaying its welcome. Sometimes, half an hour is all you need.",
    },
    {
      logo: how,
      question: "How does it work?",
      description:
        "The app connects users based on their preferences, allowing them to talk to others who need or can provide support. Whether you're seeking help or offering assistance, the app facilitates seamless voice calls to foster meaningful conversations. Simply sign up, select your interest, and you'll be connected with someone who matches your needs or can offer help.",
    },
    
    {
      logo: hourglass,
      question: "How is our price this low?",
      description:
        "At Good People, the reason you enjoy such high-quality audio and video at such a low price is simple—we’ve chosen to cut down 80% of our margin initially. This ensures we can be there for people going through difficult times, providing instant support from anyone, anywhere, whenever you need it most.",
    },
    {
      logo: premium,
      question: "Is it Available 24*7?",
      description:
        "Yes, the app is available 24/7. You can connect with others at any time, regardless of your time zone. Whether it's day or night, you'll always find someone to talk to, whether you're seeking support or providing it. Our platform ensures that help is always just a call away.",
    },
    
    
  ];

  const handleStartSpeakingClick = async() => {
    const response = await UserTalktimeApi();
    if(response)
    {
      if(response.audioTalkTime < 60)
      {
        alert(`Your Talktime is less than 1 min : ${response.audioTalkTime}. Please Recharge!`);
        return;
      }
      navigate("/talking");
    }
    else{
      navigate('/login');
    }
  };

  return (
    <div className="page-container">
      <Header />
      <div style={{justifyContent:'center',alignItems:'center',display:'flex'}}>
      <EmotionList />
      </div>
      <PromoBanner />
      <div
        className="start-speaking-container"
        onClick={handleStartSpeakingClick}
      >
        <img
          src={startSpeaking}
          alt="Start Speaking"
          className="start-speaking-image"
        />
      </div>
      <div className="faq-container">
        <img
          src={questionsLogo}
          alt="Questions Logo"
          className="questions-logo"
        />
        <h2 className="faq-title">Frequently Asked Questions</h2>
      </div>
      <div className="faq-list-container-parent">
        <div className="faq-list-container">
          {faqData.map((faq, index) => (
            <FaqTile
              key={index}
              logo={faq.logo}
              question={faq.question}
              description={faq.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
