import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RegisterApi } from "../services/Api";
import { GenerateToken } from "../services/Firebase";

const SignUp = () => {

  const handleToken = async()=>{
    const token = await GenerateToken();
    if(token) 
    {
      setToken(token);
    }
  }
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("Male");
  const [token,setToken] = useState('');
  const navigate = useNavigate();

  const validateInputs = () => {
    // Check if name is not empty
    if (!name.trim()) {
      setError("Name cannot be empty.");
      return false;
    }
    // Check if phone number is 10 digits
    if (!/^\d{10}$/.test(phone)) {
      setError("Phone number must be 10 digits.");
      return false;
    }
    // Validate email format
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setError("Enter a valid email address.");
      return false;
    }
    if (!dob) {
      setError("Please select your Date of Birth.");
      return false;
    }

    const today = new Date();
    const selectedDate = new Date(dob);
    if (selectedDate >= today) {
      setError("Date of Birth cannot be today or in the future.");
      return false;
    }
    if (!gender) {
      setError("Please select your Gender.");
      return false;
    }
    // Clear error if all validations pass
    setError("");
    return true;
  };

  const handleSignUp = async() => {
    // await handleToken();
    if(validateInputs())
    {
      console.log('handleSignUp is working');
      const response = await RegisterApi({phoneNumber:phone,name:name,email:email,password:password,dateOfBirth:dob,gender:gender,token:token});
      if(response)
      {
        console.log('SignUp API response:',response);
        localStorage.setItem("userData", JSON.stringify(response));
        // const userData = JSON.parse(localStorage.getItem("userData"));
        navigate("/", { replace: true });
      }
      
    // navigate("/", { replace: true });
    }
    
  };

  return (
    <div className="sign-up-container">
      <input
        className="input-field"
        placeholder="Enter your Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        className="input-field"
        type='tel'
        placeholder="Enter your Phone Number"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        maxLength={10}
      />
      <input
        className="input-field"
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        className="input-field"
        type="password"
        placeholder="Enter your password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <input
        className="input-field"
        type="date"
        value={dob}
        onChange={(e) => setDob(e.target.value)}
        placeholder="Select your Date of Birth"
      />
      <div className="gender-selection">
        <label>
          <input
            type="radio"
            value="Male"
            checked={gender === "Male"}
            onChange={(e) => setGender(e.target.value)}
          />
          Male
        </label>
        <label>
          <input
            type="radio"
            value="Female"
            checked={gender === "Female"}
            onChange={(e) => setGender(e.target.value)}
          />
          Female
        </label>
        <label>
          <input
            type="radio"
            value="Other"
            checked={gender === "Other"}
            onChange={(e) => setGender(e.target.value)}
          />
          Other
        </label>
      </div>
       {error && <p className="error-text">{error}</p>}
      <button className="submit-button" onClick={handleSignUp}>
        <h2 className="submit-text">Sign Up</h2>
      </button>
    </div>
  );
};

export default SignUp;
