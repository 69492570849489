import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="privacy-container">
      <div className="privacy-header">
        <h1>Privacy Policy</h1>
      </div>
      <div className="privacy-content">
        <p>
          At Good People, your privacy is our top priority. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our app. By using Good People, you agree to the terms outlined in this policy.
        </p>

        <h2>1. Information We Collect</h2>
        <ul>
          <li><strong>1.1 Personal Information:</strong> Name, email address, phone number, profile photo, and other account setup details. Payment information for bookings (e.g., credit card details).</li>
          <li><strong>1.2 Non-Personal Information:</strong> Device type, operating system, IP address, and app usage statistics.</li>
          <li><strong>1.3 Sensitive Information:</strong> Details shared during counseling sessions are encrypted and stored securely to maintain confidentiality.</li>
          <li><strong>1.4 Location Data:</strong> When booking home visits, we collect location information to facilitate the service.</li>
          <li><strong>1.5 Cookies and Tracking Technologies:</strong> We use cookies to enhance app functionality and improve user experience.</li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <ul>
          <li><strong>2.1 To Provide Services:</strong> Facilitate social connections, schedule counseling sessions, and enable app features.</li>
          <li><strong>2.2 To Improve User Experience:</strong> Analyze usage trends and improve app functionality.</li>
          <li><strong>2.3 To Ensure Safety:</strong> Detect and prevent fraudulent or unauthorized activities.</li>
          <li><strong>2.4 To Communicate:</strong> Send updates, promotional materials, and notifications.</li>
        </ul>

        <h2>3. How We Share Your Information</h2>
        <ul>
          <li><strong>3.1 With Counselors and Helpers:</strong> Share relevant details to facilitate appointments and sessions.</li>
          <li><strong>3.2 With Third-Party Service Providers:</strong> Payment processors, cloud storage providers, and analytics tools.</li>
          <li><strong>3.3 Legal Obligations:</strong> Disclose information if required by law or to protect the rights, safety, and property of users or the platform.</li>
          <li><strong>3.4 Community Moderation:</strong> Share anonymized data with moderators to ensure a safe and inclusive environment.</li>
        </ul>

        <h2>4. Data Security</h2>
        <p>
          We implement encryption, access controls, and regular security audits to protect your data. Despite these measures, no system is 100% secure, and we cannot guarantee absolute security.
        </p>

        <h2>5. Your Rights</h2>
        <ul>
          <li><strong>5.1 Access and Control:</strong> View, edit, or delete your account information at any time.</li>
          <li><strong>5.2 Withdraw Consent:</strong> Opt-out of promotional communications.</li>
          <li><strong>5.3 Request Data Deletion:</strong> Submit a request to delete your data permanently.</li>
        </ul>

        <h2>6. Third-Party Links and Services</h2>
        <p>
          The app may contain links to third-party websites or services. We are not responsible for their privacy practices.
        </p>

        <h2>7. Children’s Privacy</h2>
        <p>
          Good People is not intended for users under the age of 18. We do not knowingly collect information from minors.
        </p>

        <h2>8. Updates to This Privacy Policy</h2>
        <p>
          We may update this policy periodically. You will be notified of any significant changes.
        </p>

        <h2>9. Contact Us</h2>
        <p>If you have questions or concerns about this Privacy Policy, please contact us at:</p>
        <p>Email: support@goodpeopleapp.com</p>
        <p>Address: Noida</p>

        <h2>10. Governing Law</h2>
        <p>
          This Privacy Policy is governed by the laws of [Insert Jurisdiction].
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
