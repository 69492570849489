import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import profileImage from "../assets/profile2.png";
import GpnImage from "../assets/GPM.png";
import PremiumImage from "../assets/premium.png";
import WalletImage from "../assets/wallet.png";
import rupeeImage from "../assets/rupee.png";
import callImage from "../assets/green_call.png";
import locationImage from "../assets/location1.png";
import referralImage from "../assets/Profile/referral_code.png";
import talktimeImage from "../assets/Profile/talktime.png";
import { useNavigate, useLocation } from "react-router-dom";
import { FormatTime } from "../services/Helper";
import { UserTalktimeApi } from "../services/Api";

const ProfilePage = () => {
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    audioTalkTime: 0,
    audioTalkHours: 0,
    videoTalkTime: 0,
    videoTalkHours: 0,
  });
  // let name, audioTalkTime, audioTalkHours, helped;
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const getUserData = async () => {
    const userData = await UserTalktimeApi();
    console.log("UserTalktimeApi response:", userData);
    if (userData) {
      setUserDetails({
        name: userData.name || "",
        email: userData.email || "",
        phoneNumber: userData.phoneNumber || "",
        dateOfBirth: userData.dateOfBirth || "",
        audioTalkTime: FormatTime(userData.audioTalkTime || 0),
        audioTalkHours: FormatTime(userData.audioTalkHours || 0),
        videoTalkTime: FormatTime(userData.videoTalkTime || 0),
        videoTalkHours: FormatTime(userData.videoTalkHours || 0),
      });
    }
  };

  const checkLogin = () => {
    if (userData === null) {
      navigate("/login");
    }
  };

  useEffect(() => {
    checkLogin();
    getUserData();
  }, []);

  return (
    <div className="profile-main-container">
      <Header />

      <div className="profile-container">
        <div className="profile-left-container">
          <div className="profile-image-container">
            <img src={profileImage} className="profile-image1" />
          </div>
          <h5 className="profile-person-name">{userDetails.name}</h5>
          <div className="profile-person-details-container">
            <h5 className="profile-person-details-left-text">Email:</h5>
            <h5 className="profile-person-details-left-text">
              {userDetails.email}
            </h5>
          </div>
          <div className="profile-person-details-container">
            <h5 className="profile-person-details-left-text">Phone:</h5>
            <h5 className="profile-person-details-left-text">
              {userDetails.phoneNumber}
            </h5>
          </div>
          <div className="profile-person-details-container">
            <h5 className="profile-person-details-left-text">DOB:</h5>
            <h5 className="profile-person-details-left-text">
              {userDetails.dateOfBirth}
            </h5>
          </div>
          <div className="profile-left-bottom-img-container">
            <div className="profile-referral-image-container">
              <img src={referralImage} className="profile-referral-image1" />
            </div>

            <div className="profile-referral-image-container" onClick={() => {
              navigate("/premium");
            }}>
              <img src={talktimeImage} className="profile-referral-image1" />
            </div>
          </div>
        </div>
        <div className="profile-right-container">
          <div className="profile-right-mini-container-parent">
            <div className="profile-right-mini-container">
              <div className="profile-right-mini-container-row">
                <h5 className="profile-right-mini-container-text">
                  AudioTalktime:
                </h5>
                <h5
                  className="profile-right-mini-container-text"
                  style={{ fontWeight: "500" }}
                >
                  {userDetails.audioTalkTime}
                </h5>
              </div>
              <div className="profile-right-mini-container-row">
                <h5 className="profile-right-mini-container-text">
                  AudioTalkhour:
                </h5>
                <h5
                  className="profile-right-mini-container-text"
                  style={{ fontWeight: "500" }}
                >
                  {userDetails.audioTalkHours}
                </h5>
              </div>
            </div>
            <div className="profile-right-mini-container">
              <div className="profile-right-mini-container-row">
                <h5 className="profile-right-mini-container-text">
                  VideoTalktime:
                </h5>
                <h5
                  className="profile-right-mini-container-text"
                  style={{ fontWeight: "500" }}
                >
                  {userDetails.videoTalkTime}
                </h5>
              </div>
              <div className="profile-right-mini-container-row">
                <h5 className="profile-right-mini-container-text">
                  VideoTakhours:
                </h5>
                <h5
                  className="profile-right-mini-container-text"
                  style={{ fontWeight: "500" }}
                >
                  {userDetails.videoTalkTime}
                </h5>
              </div>
            </div>
          </div>
          {/* <div className="profile-right-mini-container-parent">
            <div className="profile-right-mini-container">
              <div className="profile-right-mini-container-row">
                <h5 className="profile-right-mini-container-text">
                  AudioTalktime:
                </h5>
                <h5
                  className="profile-right-mini-container-text"
                  style={{ fontWeight: "500" }}
                >
                  {userDetails.audioTalkTime}
                </h5>
              </div>
              <div className="profile-right-mini-container-row">
                <h5 className="profile-right-mini-container-text">
                  AudioTalkhours:
                </h5>
                <h5
                  className="profile-right-mini-container-text"
                  style={{ fontWeight: "500" }}
                >
                  {userDetails.audioTalkHours}
                </h5>
              </div>
            </div>
            <div className="profile-right-mini-container">
              <div className="profile-right-mini-container-row">
                <h5 className="profile-right-mini-container-text">
                  VideoTalktime:
                </h5>
                <h5
                  className="profile-right-mini-container-text"
                  style={{ fontWeight: "500" }}
                >
                  {userDetails.videoTalkTime}
                </h5>
              </div>
              <div className="profile-right-mini-container-row">
                <h5 className="profile-right-mini-container-text">
                  VideoTalkhours:
                </h5>
                <h5
                  className="profile-right-mini-container-text"
                  style={{ fontWeight: "500" }}
                >
                  {userDetails.videoTalkHours}
                </h5>
              </div>
            </div>
          </div> */}
        </div>

        {/* <div className="profile-header">
        </div>
        <div>
          <img
            src={profileImage}
            className="profile-page-image"
            alt="Good-People-ProfileImage"
          />
        </div>
        <div className="profile-name-container">
          <img
            src={GpnImage}
            className="profile-gpn-image"
            alt="Good-People-GpnImage"
          />
          <h3 className="profile-name">{userDetails.name}</h3>
        </div>


        <div className="profile-card">
          <div className="profile-card-elements">
            <div className="profile-card-elements-container">
              <h5 className="profile-card-element-text">Email</h5>
              <h5 className="profile-card-element-text">Phone</h5>
              <h5 className="profile-card-element-text">DOB</h5>
            </div>
            <div className="profile-card-elements-container">
             
              <h5 className="profile-card-element-text-right">
                {userDetails.email}
              </h5>
              <h5 className="profile-card-element-text-right">
                {userDetails.phoneNumber}
              </h5>
              <h5 className="profile-card-element-text-right">
                {userDetails.dateOfBirth}
              </h5>
            </div>
          </div>
          <button
            className="profile-addCredit-button"
            onClick={() => {
              navigate("/premium");
            }}
          >
            <img
              src={WalletImage}
              className="profile-wallet-image"
              alt="Good-People-walletImage"
            />
            <h3 className="profile-name"> + Add Talktime </h3>
          </button>
          <div className="profile-card-elements">
            <div className="profile-card-elements-container">
              <h5 className="profile-card-element-text">Audio TalkHours</h5>
              <h5 className="profile-card-element-text">Audio TalkTime</h5>
              <h5 className="profile-card-element-text">Video TalkHours</h5>
              <h5 className="profile-card-element-text">Video TalkTime</h5>
            </div>
            <div className="profile-card-elements-container">
              <h5 className="profile-card-element-text-right">
                {userDetails.audioTalkHours}
              </h5>
              <h5 className="profile-card-element-text-right">
                {userDetails.audioTalkTime}
              </h5>
              <h5 className="profile-card-element-text-right">
                {userDetails.videoTalkHours}
              </h5>
              <h5 className="profile-card-element-text-right">
                {userDetails.videoTalkTime}
              </h5>
            </div>
          </div>
          <div className="profile-referral-container">
            <img
              src={referralImage}
              className="profile-referral-image"
              alt="Good-People-walletImage"
              onClick={null}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ProfilePage;
