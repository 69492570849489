import React, { useState } from "react";
import { signInWithPhoneNumber, ConfirmationResult } from "firebase/auth";
import { auth, setupRecaptcha } from "../services/Firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { UserValidateApi } from "../services/Api";

const OtpPage = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [error, setError] = useState("");
  const location = useLocation();
  const { flag } = location.state || {}; // Access the flag from state
  console.log("OtpPage Flag:", flag);
  const navigate = useNavigate();

  const verifyPhoneFromDb = async () => {
    const data = { phoneNumber: phoneNumber };
    const response = await UserValidateApi(data);
    console.log("verifyPhoneFromDb response:", response);
    if (response.status === "success") {
      //user exists
      return true;
    } else if (response.status === "failure") {
      //user dosen't exists
      return false;
    }
  };

  const handleChangePass = async () => {
    //User came from change password button
    if (phoneNumber.length < 9) {
      setError("Please enter a valid 10-digit phone number");
      return;
    }
    const checkUserExists = await verifyPhoneFromDb();
    if (checkUserExists) {
      await handleSendOtp();
    } else {
      setError(`Phone does not exist.`);
    }
  };

  const handleSignUp = async () => {
    //User came from change SignUp button
    if (phoneNumber.length > 9) {
      setError("Please enter a valid 10-digit phone number");
      return;
    }
  };

  const handleSendOtp = async () => {
   
    setupRecaptcha(auth);
    
    const appVerifier = window.recaptchaVerifier;
    try {
      const result = await signInWithPhoneNumber(
        auth,
        `+91${phoneNumber}`,
        appVerifier
      );
      setConfirmationResult(result);
      setIsOtpSent(true);
      setError("OTP sent successfully! with result:",result);
    } catch (error) {
      console.log("Error sending OTP:", error);
    }
  };

  const handleVerifyOtp = async () => {
    if (otp.length !== 6) {
      setError("Please enter a valid 6-digit OTP");
      return;
    }
    try {
      const userCredential = await confirmationResult.confirm(otp);
      console.log("User signed in successfully:", userCredential.user);
      if(flag){
        navigate('/forgotPass');
      }
      else{
        //hit signup api 
        navigate('/referral')
      }
      setError("Phone number verified successfully!");
    } catch (error) {
      console.log("Error verifying OTP:", error);
    }
  };

  return (
    <div className="otp-container">
      <h3 className="otp-title">OTP VERIFICATION</h3>
      <div id="recaptcha-container"></div>
      {!isOtpSent ? (
        <div className="phone-input-container">
          <input
            type="text"
            className="phone-input"
            placeholder="Enter your phone(10 digits)"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            maxLength={10}
          />
          <button
            className="send-otp-button"
            onClick={() => {
                if (flag) {
                  handleChangePass();
                } else {
                  handleSignUp();
                }
              }}
          >
            <h3 className="send-otp-button-text">Send OTP</h3>
          </button>
        </div>
      ) : (
        <div className="otp-input-container">
          <input
            type="text"
            className="otp-input"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <button
            className="verify-otp-button"
            onClick={handleVerifyOtp}
          >
            Verify OTP
          </button>
        </div>
      )}
      {error && <p className="error-message">{error}</p>}
      <div id="recaptcha-container"></div>
    </div>
  );
};

export default OtpPage;
