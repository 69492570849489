import React from "react";
import PremiumRate from "../components/PremiumRate";


const PremiumPage =()=>{
    return (<div className="premium-container">
        <div className="premium-page-header">
            <h3 className="premium-page-header-title">Recharge</h3>
            <h3 className="premium-page-header-message">Please don't disclose the contacts, trust the app for secure conversations</h3>
        </div>
    
        <PremiumRate/>
    </div>);
}

export default PremiumPage;