export const FormatTime=(seconds)=> {
    const hrs = Math.floor(seconds / 3600); 
    const mins = Math.floor((seconds % 3600) / 60); 
    const secs = seconds % 60; 
  
    const formattedTime = [
      hrs > 0 ? `${hrs}h` : null, 
      mins > 0 ? `${mins}m` : null,
      `${secs}s`,
    ]
      .filter(Boolean) 
      .join(" "); 
  
    return formattedTime;
  }
  