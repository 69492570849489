import React, { useEffect, useState } from "react";
import PromoBanner from "../components/PromoBanner";
import reloadImage from "../assets/reload.png";
import searchImage from "../assets/search_icon.png";
import micImageImage from "../assets/mic.png";
import maleProfileImage from "../assets/male.png";
import femaleProfileImage from "../assets/female.png";
import micOnImage from "../assets/mic_on.png";
import micOffImage from "../assets/mic_off.png";
import callCutImage from "../assets/cut_call.png";
import callAddImage from "../assets/add_call.png";
import CircularWaveAnimation from "../components/CircularWaveAnimation";
import { useNavigate, useLocation } from "react-router-dom";
import {
  LocalUser,
  RemoteUser,
  useIsConnected,
  useJoin,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteUsers,
  useRTCClient,
  useClientEvent,
} from "agora-rtc-react";
import {
  AgoraEndApi,
  AgoraStartApi,
  GetFriendDetailsApi,
} from "../services/Api";

const FriendsTalkingPage = () => {
  const location = useLocation();
  const { token1, channelName1, channelId1 } = location.state;
  const navigate = useNavigate();
  const headerText = `So as long as you can sweeten another's pain, life is not in vain`;
  const [wave, setWave] = useState(true);
  const [isMute, setIsMute] = useState(false);
  const [isSpeaker, setIsSpeaker] = useState(false);
  const isConnected = useIsConnected();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const name = userData.name;
  const client = useRTCClient();

  const [calling, setCalling] = useState(false); // Is calling
  const [appId, setAppId] = useState("c8cee9b7eb724c3a95feeeb9c39e77d8"); // Store the app ID state
  const [channel, setChannel] = useState(""); // Store the channel name state
  const [channelId, setChannelId] = useState("");
  const [token, setToken] = useState("");

  //Both user details state
  const [senderId, setSenderId] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [callerName, setCallerName] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setCalling(true);
    }, 3000);

    // Cleanup function to clear the timer
    return () => clearTimeout(timer);
  }, []);

  // useEffect(() => {
  //   startAgora();
  // }, []);

  // const startAgora = async () => {
  //   const response = await AgoraStartApi();
  //   // console.log('startAgora working AgoraStartApi response:',response)

  //   if (response) {
  //     console.log("AgoraStartApi response:", response);
  //     setChannel(response.channelName);
  //     setToken(response.token);
  //     setChannelId(response.channelId);
  //     setCalling(true);
  //   }
  // };
  const friendDetails = async () => {
    const response = await GetFriendDetailsApi(channelId1);
    console.log("GetFriendDetailsApi response:", response);
    let senderId = "",
      receiverId = "",
      callerName = "";
    if (response.callerName === name) {
      senderId = String(response.callerid);
      receiverId = String(response.receiverid);
      callerName = String(response.receiverName);
    } else {
      senderId = String(response.receiverid);
      receiverId = String(response.callerid);
      callerName = String(response.callerName);
    }
    console.log(
      "GetFriendDetailsApi state variable data setting",
      callerName,
      senderId,
      receiverId
    );
    setSenderId(senderId);
    setReceiverId(receiverId);
    setCallerName(callerName);
  };

  useJoin(
    { appid: appId, channel: channelName1, token: token1 ? token1 : null },
    calling
  );
  const [micOn, setMic] = useState(true);
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  usePublish([localMicrophoneTrack]);
  const remoteUsers = useRemoteUsers();

  useEffect(() => {
    remoteUsers.forEach((user) => {
      if (user.hasAudio) {
        setWave(false);
        console.log(`Playing audio from remote user: ${user.uid}`);
        user.audioTrack?.play(); // Play remote user's audio
        friendDetails();
      }
    });
    if (remoteUsers.length === 0 && calling) {
      console.log("Remote user has left the call.");

      Leave(); // End the call and navigate back
    }
  }, [remoteUsers]);

  useClientEvent(client, "user-left", () => {
    Leave();
  });

  const Leave = async () => {
    await friendDetails();
    const response = await AgoraEndApi({ time: 0,isVideo:false });
    if (response) {
      console.log("AgoraEndApi response:", response);
      navigate("/", { replace: true });
      // if (wave) {
      //   navigate("/", { replace: true });
      //   return;
      // }
      console.log(
        "Sending props to RatingPage senderId",
        senderId,
        "receiverId",
        receiverId
      );

      // navigate("/rating", {
      //   replace: true,
      //   state: {
      //     senderId: senderId,
      //     receiverId: receiverId,
      //     callerName: callerName,
      //   },
      // });
    } else {
      navigate("/", { replace: true });
    }
  };

  const waveComponent = () => {
    return (
      <div className="talking-wave-component-container">
        <CircularWaveAnimation />
        <div className="talking-wave-message">
          <h3>Our App has users all over India!</h3>
        </div>
      </div>
    );
  };

  const notWaveComponent = () => {
    return (
      <div className="talking-not-wave-component-container">
        <div className="talking-profile-image-text">
          <img
            src={maleProfileImage}
            className="talking-profile-image"
            alt="Good-People"
          />
          <h3 className="talking-header-text">{name ? name : ""}</h3>
        </div>
        <img
          src={micImageImage}
          className="talking-mic-image"
          alt="Good-People"
        />
        <div className="talking-profile-image-text">
          <img src={femaleProfileImage} className="talking-profile-image" />
          <h3 className="talking-header-text">
            {callerName ? callerName : ""}
          </h3>
        </div>
      </div>
    );
  };

  const handleMicClick = () => {
    setIsMute(!isMute);
  };

  const handleSpeakerClick = () => {
    setIsSpeaker(!isSpeaker);
  };

  const footerComponent = () => {
    return (
      <div className="talking-footer-container">
        <button onClick={handleMicClick} className="talking-footer-button">
          <img
            src={isMute ? micOffImage : micOnImage}
            className="talking-footer-icon"
            alt="Good-People"
          />
        </button>
        {/* <button onClick={handleSpeakerClick} className="talking-footer-button">
          <img
            src={isSpeaker ? speakerOffImage : speakerOnImage}
            className="talking-footer-icon"
            alt="Good-People"
          />
        </button> */}
        <button onClick={Leave} className="talking-footer-button">
          <img
            src={callCutImage}
            className="talking-footer-icon"
            alt="Good-People"
          />
        </button>
        <button onClick={null} className="talking-footer-button">
          <img
            src={callAddImage}
            className="talking-footer-icon"
            alt="Good-People"
          />
        </button>
      </div>
    );
  };

  return (
    <div>
      <div className="talking-header-container">
        <h1 className="talking-header-text">{headerText}</h1>
      </div>
      {remoteUsers.map((user) => (
        <div key={user.uid}>
          <RemoteUser user={user}>
            <samp>{user.uid}</samp>
          </RemoteUser>
        </div>
      ))}
      <div className="talking-change-quotes-button-container">
        {wave ? (
          <div className="talking-finding-friend-container">
            <img
              src={searchImage}
              className="talking-search-icon"
              alt="Good-People"
            />
            <h3>Finding a friend for you!</h3>
          </div>
        ) : (
          <button
            onClick={() => alert("Button clicked!")}
            className="talking-change-quotes-button"
          >
            <h3>Change Quotes</h3>
            <img
              src={reloadImage}
              className="talking-reload-icon"
              alt="Good-People"
            />
          </button>
        )}
      </div>
      <PromoBanner />
      {wave ? waveComponent() : notWaveComponent()}
      {footerComponent()}
    </div>
  );
};

export default FriendsTalkingPage;
