import React, { useEffect, useState } from "react";
import {
  RazorPayOrderIdApi,
  RazorPayPaymentStatusApi,
  UserTalktimeApi,
} from "../services/Api";
import { FormatTime } from "../services/Helper";
import audioPriceImage from "../assets/audio_price.png";
import videoPriceImage from "../assets/video_price.png";
import paytmImage from "../assets/recharge/Paytm.png";
import paypalImage from "../assets/recharge/Paypal.png";
import gpayImage from "../assets/recharge/Gpay.png";
import phonepayImage from "../assets/recharge/phonepay.png";
import mastercardImage from "../assets/recharge/mastercard.png";
import viseImage from "../assets/recharge/visa.png";
import card1Image from "../assets/recharge/card1.png";
import card2Image from "../assets/recharge/card2.png";
import card3Image from "../assets/recharge/card3.png";
import Marquee from "react-fast-marquee";

function PremiumRate() {
  const [audioHours, setAudioHours] = useState(1);
  const [videoHours, setVideoHours] = useState(1);
  const [audioTalkTime, setAudioTalkTime] = useState();
  const [videoTalkTime, setVideoTalkTime] = useState();
  const audioRatePerHour = 20;
  const videoRatePerHour = 50;

  const totalAudioAmount = audioHours * audioRatePerHour;
  const totalVideoAmount = videoHours * videoRatePerHour;

  const data = [
    { id: "1", image: paytmImage },
    { id: "2", image: paypalImage },
    { id: "3", image: gpayImage },
    { id: "4", image: phonepayImage },
    { id: "5", image: mastercardImage },
    { id: "6", image: viseImage },
  ];

  const cardData = [
    { id: "1", image: card1Image },
    { id: "2", image: card2Image },
    { id: "3", image: card3Image },
  ];

  useEffect(() => {
    getTalktime();
  }, []);

  const getTalktime = async () => {
    const userData = await UserTalktimeApi();
    const audioTime = FormatTime(userData.audioTalkTime);
    setAudioTalkTime(audioTime);
    const videoTime = FormatTime(userData.videoTalkTime);
    setVideoTalkTime(videoTime);
  };

  const incrementAudioHours = () => setAudioHours(audioHours + 1);
  const decrementAudioHours = () => {
    if (audioHours > 1) setAudioHours(audioHours - 1); // Minimum 1 hour
  };

  const incrementVideoHours = () => setVideoHours(videoHours + 1);
  const decrementVideoHours = () => {
    if (videoHours > 1) setVideoHours(videoHours - 1); // Minimum 1 hour
  };

  const handlePay = async (amount, orderType) => {
    console.log("HandlePay Amount", amount);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const name = userData.name;
    const email = userData.email;
    const phone = userData.phoneNumber;
    const razorpayKey = process.env.REACT_APP_RAZORPAY_KEY;
    try {
      const response = await RazorPayOrderIdApi({ amount, orderType });
      console.log("RazorPayOrderIdApi response:", response);
      const orderId = response.orderId;
      const options = {
        key: razorpayKey,
        amount: amount * 100,
        currency: "INR",
        name: "Good People",
        description: `Talktime amount ${amount}`,
        image:
          "https://goodpeople.live/static/media/logo.160934d3c48e5910d916.png", // Replace with your logo URL
        order_id: orderId,
        handler: async function (data) {
          console.log("Payment Success:", data);
          const paymentId = data.razorpay_payment_id;
          const signature = data.razorpay_signature;
          const response = await RazorPayPaymentStatusApi({
            paymentId: paymentId,
            signature: signature,
            orderId: orderId,
          });
          console.log("RazorPayPaymentStatusApi response", response);
        },
        prefill: {
          name: name,
          email: email,
          contact: phone,
        },
        notes: {
          address: "GoodPeople Corporate Office",
        },
        theme: {
          color: "#22BBC7",
        },
      };

      const razorpay = new window.Razorpay(options);

      razorpay.on("payment.failed", function (response) {
        console.error("Payment Failed:", response);
        // Handle payment failure
      });

      razorpay.open();
    } catch (error) {
      console.log("Payment initialization error:", error);
      alert("Payment initialization failed. Please try again.");
    }
  };

  return (
    <div className="premium-rate-main-container">
      <div className="premium-rate-main-container1">
        <div className="premium-rate-container">
          <h2 className="premium-rate-title">Balance: {audioTalkTime}</h2>

          <p className="premium-rate-description">
            <img src={audioPriceImage} className="premium-audio-image" />
          </p>
          <div className="premium-rate-counter-container">
            <button
              onClick={decrementAudioHours}
              className="premium-rate-counter-button"
            >
              -
            </button>
            <span className="premium-rate-hours-text">
              {audioHours} hour(s)
            </span>
            <button
              onClick={incrementAudioHours}
              className="premium-rate-counter-button"
            >
              +
            </button>
          </div>
          <button
            className="premium-rate-buy-button"
            onClick={() => {
              handlePay(totalAudioAmount, "AudioTalkTime");
            }}
          >
            Buy Now (Rs {totalAudioAmount})
          </button>
        </div>

        <div className="premium-rate-container">
          <h2 className="premium-rate-title">Balance: {videoTalkTime}</h2>

          <p className="premium-rate-description">
            <img src={videoPriceImage} className="premium-audio-image" />
          </p>
          <div className="premium-rate-counter-container">
            <button
              onClick={decrementVideoHours}
              className="premium-rate-counter-button"
            >
              -
            </button>
            <span className="premium-rate-hours-text">
              {videoHours} hour(s)
            </span>
            <button
              onClick={incrementVideoHours}
              className="premium-rate-counter-button"
            >
              +
            </button>
          </div>
          <button
            className="premium-rate-buy-button"
            onClick={() => {
              handlePay(totalVideoAmount, "VideoTalkTime");
            }}
          >
            Buy Now (Rs {totalVideoAmount})
          </button>
        </div>
      </div>
      <div className="premium-rate-main-container2">
        {data.map((item) => (
          <div className="premium-rate-paymentlogo-container" key={item.id}>
            <img
              src={item.image}
              alt={item.text}
              className="premium-rate-paymentlogo"
            />
          </div>
        ))}
      </div>
      <div className="premium-rate-main-container3">
        {cardData.map((item) => (
          <div className="premium-rate-cardlogo-container" key={item.id}>
            <img
              src={item.image}
              alt={item.text}
              className="premium-rate-cardlogo"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PremiumRate;
