import React, { useEffect, useRef, useState } from "react";
import micOnImage from "../assets/mic_on.png";
import micOffImage from "../assets/mic_off.png";
import camaraOnImage from "../assets/camara_on.png";
import camaraOffImage from "../assets/camara_off.png";
import callCutImage from "../assets/cut_call.png";
import callAddImage from "../assets/add_call.png";
import CircularWaveAnimation from "../components/CircularWaveAnimation";
import { useNavigate } from "react-router-dom";
import {
  LocalUser,
  RemoteUser,
  useIsConnected,
  useJoin,
  useLocalMicrophoneTrack,
  useLocalCameraTrack,
  usePublish,
  useRemoteUsers,
  useRTCClient,
  useClientEvent,
} from "agora-rtc-react";
import {
  AgoraEndApi,
  AgoraStartVideoApi,
  GetFriendDetailsApi,
  UserTalktimeApi,
} from "../services/Api";
import { FormatTime } from "../services/Helper";

const VideoCallPage = () => {
  const navigate = useNavigate();
  const headerText = `So as long as you can sweeten another's pain, life is not in vain`;
  const [wave, setWave] = useState(true);
  const [isMute, setIsMute] = useState(false);
  const [isCamara, setIsCamara] = useState(false);
  const isConnected = useIsConnected();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const name = userData.name;
  const client = useRTCClient();

  const [calling, setCalling] = useState(false); // Is calling
  const [appId, setAppId] = useState("c8cee9b7eb724c3a95feeeb9c39e77d8"); // Store the app ID state
  const [channel, setChannel] = useState(""); // Store the channel name state
  const [channelId, setChannelId] = useState("");
  const [token, setToken] = useState("");
  const timeoutRef = useRef(null);

  //Timer
  const [seconds, setSeconds] = useState(0);
  const timerRef = useRef(null);

  const startTimer = () => {
    if (!timerRef.current) {
      timerRef.current = setInterval(() => {
        setSeconds((prev) => prev + 1);
      }, 1000);
    }
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  //Both user details state
  const [senderId, setSenderId] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [callerName, setCallerName] = useState("");

  useEffect(() => {
    startAgora();
  }, []);

  const handleAutoCut = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const time = userData.audioTalkTime;
    console.log("handleAutoCut time:", time);
    setTimeout(() => {
      console.log("handleAutoCut setTimeout seconds:", seconds);
      stopTimer();
      Leave();
    }, time*100);
  };

  const startTimeout = async () => {
    try {
      const response = await UserTalktimeApi();
      const videoTalkTime = response.videoTalkTime;
      console.log('VideoTalkTime AutoCut', videoTalkTime);
      timeoutRef.current = setTimeout(() => {
        Leave();
        stopTimer();
        alert('You have no Talktime Left Please Recharge');
      }, videoTalkTime * 1000);
    } catch (error) {
      console.error('Error fetching timeout value:', error);
    }
  };

  const stopTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const startAgora = async () => {
    const response = await AgoraStartVideoApi();

    if (response) {
      console.log("AgoraStartVideoApi response:", response);
      setChannel(response.channelName);
      setToken(response.token);
      setChannelId(response.channelId);
      setCalling(true);
    }
  };

  const friendDetails = async () => {
    const response = await GetFriendDetailsApi(channelId);
    let senderId = "",
      receiverId = "",
      callerName = "";
    if (response.callerName === name) {
      senderId = String(response.callerid);
      receiverId = String(response.receiverid);
      callerName = String(response.receiverName);
    } else {
      senderId = String(response.receiverid);
      receiverId = String(response.callerid);
      callerName = String(response.callerName);
    }
    setSenderId(senderId);
    setReceiverId(receiverId);
    setCallerName(callerName);
  };

  useJoin(
    { appid: appId, channel: channel, token: token ? token : null },
    calling
  );
  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  const { localCameraTrack } = useLocalCameraTrack(cameraOn);
  usePublish([localMicrophoneTrack, localCameraTrack]);
  const remoteUsers = useRemoteUsers();
  // User Joined Event
  useEffect(() => {
    remoteUsers.forEach((user) => {
      if (user.hasAudio) {
        setWave(false);
        user.audioTrack?.play(); // Play remote user's audio
        friendDetails();
        startTimer();
        startTimeout();
        // handleAutoCut();
      }
    });
    // User Left Event
    if (remoteUsers.length === 0 && calling) {
      // console.log("Remote user has left the call.");
      stopTimer();
      Leave();
    }
  }, [remoteUsers]);

  // useClientEvent(client, "user-left", () => {

  //   Leave();
  // });


  const Leave = async () => {
    if (wave) {
      navigate("/", { replace: true });
      return;
    }
    await friendDetails();
    const response = await AgoraEndApi({ time: seconds,isVideo:true });
    stopTimeout();
    if (response) {
      console.log("AgoraEndApi response:", response);
      
      console.log(
        "Sending props to RatingPage senderId",
        senderId,
        "receiverId",
        receiverId
      );

      navigate("/rating", {
        replace: true,
        state: {
          senderId: senderId,
          receiverId: receiverId,
          callerName: callerName,
        },
      });
    } else {
      navigate("/", { replace: true });
    }
  };

  const waveComponent = () => {
    return (
      <div className="talking-wave-component-container">
        <CircularWaveAnimation />
      </div>
    );
  };

  const notWaveComponent = () => {
    return (
      <>
        <div className="talking-not-wave-component-container">
          <div className="video-call-remoteUser">
            <LocalUser
              audioTrack={localMicrophoneTrack}
              cameraOn={cameraOn}
              micOn={micOn}
              videoTrack={localCameraTrack}
              cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
            >
            </LocalUser>
            <h3 className="talking-page-timer-text">You</h3>
          </div>
          {remoteUsers.map((user) => (
            <div className="video-call-remoteUser" key={user.uid}>
              <RemoteUser
                cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
                user={user}
              >
              </RemoteUser>
              <h3 className="talking-page-timer-text">{callerName}</h3>
            </div>
          ))}
        </div>
        <h3 className="talking-page-timer-text">Timer:{FormatTime(seconds)}</h3>
      </>
    );
  };

  const handleMicClick = async () => {
    try {
      if (!localMicrophoneTrack) {
        console.warn("Local microphone track not initialized.");
        return;
      }

      if (isMute) {
        // Unmute microphone
        await localMicrophoneTrack.setEnabled(true);
        console.log("Microphone unmuted");
      } else {
        // Mute microphone
        await localMicrophoneTrack.setEnabled(false);
        console.log("Microphone muted");
      }

      setIsMute(!isMute); // Toggle the mute state
    } catch (error) {
      console.error("Error toggling microphone state:", error);
    }
  };
  const handleCamaraClick = async () => {
    try {
      if (!localCameraTrack) {
        console.warn("Local Camara track not initialized.");
        return;
      }

      if (isCamara) {
        // Unmute microphone
        await localCameraTrack.setEnabled(true);
        console.log("Camara Disabled");
      } else {
        // Mute microphone
        await localCameraTrack.setEnabled(false);
        console.log("Camara Enabled");
      }

      setIsCamara(!isCamara); // Toggle the mute state
    } catch (error) {
      console.error("Error toggling microphone state:", error);
    }
  };

  const footerComponent = () => {
    return (
      <div className="talking-footer-container">
        <button onClick={handleCamaraClick} className="talking-footer-button">
          <img
            src={isCamara ? camaraOffImage : camaraOnImage}
            className="talking-footer-icon"
            alt="Good-People"
          />
        </button>
        <button onClick={handleMicClick} className="talking-footer-button">
          <img
            src={isMute ? micOffImage : micOnImage}
            className="talking-footer-icon"
            alt="Good-People"
          />
        </button>
        <button onClick={Leave} className="talking-footer-button">
          <img
            src={callCutImage}
            className="talking-footer-icon"
            alt="Good-People"
          />
        </button>
        <button onClick={null} className="talking-footer-button">
          <img
            src={callAddImage}
            className="talking-footer-icon"
            alt="Good-People"
          />
        </button>
      </div>
    );
  };

  return (
    <div className="video-call-container">
      <div className="talking-header-container">
        <h1 className="talking-header-text">{headerText}</h1>
      </div>

      {wave ? waveComponent() : notWaveComponent()}
      {footerComponent()}
    </div>
  );
};

export default VideoCallPage;
