import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";
import { BrowserRouter, useLocation } from "react-router-dom";
import Footer from "./components/Footer";

AgoraRTC.setLogLevel(4);

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

const Layout = () => {
  const location = useLocation();
  const excludedRoutes = ["/privacyPolicy",'/otp','/termsAndConditions','/talking','/rating','/videoCall','/premium']; // Add routes where the footer should not appear

  return (
    <>
      <App />
      {!excludedRoutes.includes(location.pathname) && <Footer />}
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AgoraRTCProvider client={client}>
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  </AgoraRTCProvider>
);
