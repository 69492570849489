import React, { useEffect, useState } from "react";
import defaultProfilePic from "../assets/profile2.png"; // Static image for null profile pictures
import {
  GetFriendRequestListApi,
  AcceptFriendRequestApi,
  DeleteFriendRequestApi,
} from "../services/Api";

const FriendRequestPage = () => {
  const [friendRequests, setFriendRequests] = useState([]);

  useEffect(() => {
    fetchFriendRequests();
  }, []);

  const fetchFriendRequests = async () => {
    const response = await GetFriendRequestListApi();
    if (response) {
      // console.log("GetFriendRequestListApi response:", response);
      setFriendRequests(response);
      
    }
  };

  const handleAccept = async (tableId) => {
    const response = await AcceptFriendRequestApi(tableId);
    if (response) {
      console.log("AcceptFriendRequestApi response:", response);
      fetchFriendRequests();
      alert("Friend request accepted successfully");
    } else {
      alert("Unable to accept the friend request");
    }
  };

  const handleReject = async (tableId) => {
    const response = await DeleteFriendRequestApi(tableId);
    console.log("DeleteFriendRequestApi response:", response);
    fetchFriendRequests();
    alert("Friend request deleted successfully");
  };

  return (
    <div className="friend-request-page-container">
      <h1 className="friend-request-page-title">Friend Requests</h1>
      <div className="friend-request-list">
        {friendRequests.map((request) => (
          <div key={request.id} className="friend-request-item">
            <img
              src={request.senderProfilePic || defaultProfilePic}
              alt={request.senderName}
              className="friend-request-profile-pic"
            />
            <div className="friend-request-sender-name">
              {request.senderName}
            </div>
            <div className="friend-request-actions">
              <button
                className="friend-request-accept-button"
                onClick={() => handleAccept(request.id)}
              >
                Accept
              </button>
              <button
                className="friend-request-reject-button"
                onClick={() => handleReject(request.id)}
              >
                Reject
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FriendRequestPage;
